import { Caption, Checkbox, Margin, TextField } from '@kivra/react-components';
import React from 'react';
import type { ValidateResult } from 'react-hook-form';
import { Controller, useFormContext } from 'react-hook-form';
import { useCampaignStatus } from '../../../../../context/campaignContext';
import { useSso } from '../../../../../context/senderContext';
import type { CampaignForm } from '../../../../../types/campaignForm';
import { isOngoing } from '../../../../../util/campaignStatus';
import { getCopy } from '../../../../../util/copy';
import { Block } from '../block/Block';

export const LinkField = (): React.JSX.Element => {
  const { control, formState, watch, trigger } = useFormContext<CampaignForm>();
  const campaignStatus = useCampaignStatus();
  const showSso = useSso() || watch('useSso');

  const httpsValidation = (value: string): ValidateResult => {
    if (value === '') {
      return true;
    }

    return (
      value.startsWith('https://') || getCopy('misc__validate_url_error_https')
    );
  };

  const isValidUrl = (value: string): boolean => {
    try {
      new URL(value);
    } catch {
      return false;
    }

    return true;
  };

  const characterValidation = (value: string): ValidateResult => {
    const illegalCharacterRegex = /[^A-Za-z0-9:/?#[\]@!$&'()*+,;=.\-_~%]/g;
    const illegalCharacters = value.match(illegalCharacterRegex);
    if (illegalCharacters && illegalCharacters.length > 0) {
      return getCopy('misc__validate_url_error_illegal_characters');
    }
  };

  const formatValidation = (value: string): ValidateResult => {
    if (value === '') {
      return true;
    }

    return isValidUrl(value) || getCopy('misc__validate_url_error_format');
  };

  const validationRules = {
    validate: {
      https: httpsValidation,
      characters: characterValidation,
      format: formatValidation,
    },
  };

  const isOngoingCampaign = isOngoing(campaignStatus);
  return (
    <Block.Wrapper label={getCopy('campaigns__link')} optional={true}>
      <Controller
        name="destinationUrl"
        control={control}
        rules={validationRules}
        render={props => {
          const errorType = formState.errors.destinationUrl?.type as
            | 'https'
            | 'characters'
            | 'format'
            | undefined;
          const validationMessage = formState.errors.destinationUrl?.message;

          return (
            <TextField
              disabled={isOngoingCampaign}
              size="small"
              value={props.field.value}
              placeholder="https://"
              onTextChange={props.field.onChange}
              onBlur={() => {
                props.field.onBlur();
              }}
              errorMessage={errorType && validationMessage}
            />
          );
        }}
      />
      {showSso && (
        <Controller
          name="useSso"
          control={control}
          render={props => {
            const onChange = (value: boolean): void => {
              props.field.onChange(value);
              void trigger('destinationUrl');
            };
            return (
              <Margin top={8}>
                <Checkbox
                  disabled={isOngoingCampaign}
                  label={getCopy('campaigns__enable_sso_label')}
                  checked={props.field.value}
                  onChange={onChange}
                />
                <Margin top={8}>
                  <Caption>{getCopy('campaigns__enable_sso_hint')}</Caption>
                </Margin>
              </Margin>
            );
          }}
        />
      )}
    </Block.Wrapper>
  );
};
